import { Component, OnInit } from '@angular/core'
import { DashboardAPIService, DashboardDataCustomer, OrderDraftDTO, OrderDraftsAPIService } from '@slovgen-ui/api'
import { Observable } from 'rxjs'

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
    overview: { total?: number; completed?: number; inProgress?: number } = {}
    data$: Observable<DashboardDataCustomer>
    drafts$: Observable<OrderDraftDTO[]>

    constructor(private api: DashboardAPIService, private orderDraftApi: OrderDraftsAPIService) {}

    ngOnInit(): void {
        this.data$ = this.api.getCustomerDashboard()
        this.drafts$ = this.orderDraftApi.getActiveDrafts()
    }

    deleteDraft() {
        this.orderDraftApi.deleteActiveDraft().subscribe({
            next: () => {
                this.drafts$ = this.orderDraftApi.getActiveDrafts()
            },
        })
    }
}
