import { HttpClient } from '@angular/common/http'
import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { OrderItemListItem, SampleListItem, SamplesAPIService } from '@slovgen-ui/api'
import { CacheService, SelectItem, State, Stateful } from '@slovgen-ui/shared'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'

@Component({
    templateUrl: './my-samples.component.html',
    styleUrls: ['./my-samples.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
@Stateful({ name: 'MySamplesComponent' })
export class MySamplesComponent implements OnInit {
    form: UntypedFormGroup
    loadingIndicator: boolean
    @State()
    samplesList$: Observable<SampleListItem[]>

    @State()
    rootCategoriesSelectItems: SelectItem<number>[]
    categoriesMap: Map<number, SelectItem<number>[]>
    orderList$: Observable<OrderItemListItem[]>

    @State()
    searchCriteria: any

    constructor(
        private fb: UntypedFormBuilder,
        private api: SamplesAPIService,
        public cache: CacheService,
        private httpClient: HttpClient
    ) {
        const dateMinus12Months = new Date()
        dateMinus12Months.setMonth(dateMinus12Months.getMonth() - 12)
        this.form = this.fb.group({
            labCode: [],
            protocolNo: [],
            name: [],
            identifier: [],
            furColor: [],
            dateFrom: [dateMinus12Months],
            dateTo: [new Date()],
            rootCategoryId: [],
            categoryId: [],
        })
    }

    downloadFile(orderId: number, itemId: number, protocolId: string) {
        this.httpClient
            .get(`/api/protocols/${orderId}/${itemId}/pdf?record=true`, { responseType: 'blob', observe: 'response' })
            .subscribe((resp) => {
                const blob = new Blob([resp.body], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = `protocol-${protocolId || itemId}.pdf`
                const dispo = resp.headers.get('Content-Disposition')
                if (dispo && dispo.indexOf('filename=') !== -1) {
                    link.download = dispo.split('filename=')[1]
                }
                link.click()
            })
    }

    ngOnInit() {
        // init data from cache
        if (this.searchCriteria) {
            this.form.patchValue(this.searchCriteria)
        }
        this.rootCategoriesSelectItems = this.cache.rootCategoriesSelectItems
        this.search()
    }
    onRootCategorySelected(selectedRootCat: SelectItem<number>) {
        if (!selectedRootCat) {
            this.form.get('categoryId').reset()
        }
    }
    get f() {
        return this.form.controls
    }
    search() {
        this.searchCriteria = this.form.value
        this.loadingIndicator = true
        this.samplesList$ = this.api
            .searchSamples({
                sampleCriteria: {
                    ...this.form.value,
                },
            })
            .pipe(
                finalize(() => {
                    this.loadingIndicator = false
                })
            )
    }

    onDetailToggle(event: { data: SampleListItem }) {
        console.dir(event)
        this.orderList$ = this.api.getSampleAnalyses({ id: event.data.id })
    }
}
