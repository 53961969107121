import { Component, OnDestroy, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    InvoiceMode,
    NewOrderItemDTO,
    NewSampleDTO,
    OrderDraftsAPIService,
    OrdersAPIService,
    ResultNotificationMode,
    SampleListItem,
    SamplesAPIService,
    SubmitNewOrderRequestParams,
} from '@slovgen-ui/api'
import {
    ItemFormModel,
    OrderFormBuilder,
    OrderFormHeaderModel,
    OrderFormModel,
    OrderPersistManager,
    SampleFormModel,
} from '@slovgen-ui/order-components'
import { AuthService, MessageService, SelectItem } from '@slovgen-ui/shared'
import { ConfirmationService } from 'primeng/api'
import { Observable, Subscription, of } from 'rxjs'
import { catchError, debounceTime, delay, finalize, map, switchMap, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'

const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' }

export function requiredIfSampling(formControl: FormControl) {
    if (!formControl.parent) {
        return null
    }

    if (formControl.parent.get('samplingKitType').value !== -1) {
        return Validators.required(formControl)
    }
    return null
}

@Component({
    templateUrl: './new-order.component.html',
    styleUrls: ['./new-order.component.scss'],
    providers: [ConfirmationService],
})
@UntilDestroy()
export class NewOrderComponent implements OnInit, OnDestroy {
    loading = false
    mainForm: FormGroup<OrderFormModel>
    adminMode = false
    customerId: number
    step: 'SAMPLES' | 'COMPLETION' | 'SUMMARY' = 'SAMPLES'
    progress = 1
    customerSamples$: Observable<SelectItem<number>[]>
    submitting: boolean
    samplesFormArray: FormArray
    development = !environment.production
    formPersistSub$: Subscription
    orderFB: OrderFormBuilder
    orderSaveInProgress: boolean

    constructor(
        private fb: FormBuilder,
        private orderApi: OrdersAPIService,
        private samplesApi: SamplesAPIService,
        private auth: AuthService,
        private msgService: MessageService,
        private router: Router,
        private confirmationService: ConfirmationService,
        private orderDraftApi: OrderDraftsAPIService
    ) {
        this.orderFB = new OrderFormBuilder(this.fb)
        const samplingKitTypeFC = this.fb.control(-1)
        const samplingKitCount = this.fb.control(null, requiredIfSampling)

        this.samplesFormArray = this.fb.array([this.orderFB.createSampleForm(false)])
        this.mainForm = fb.group<OrderFormModel>({
            orderHeader: this.fb.group<OrderFormHeaderModel>({
                paymentMethod: ['BANK_TRANSFER'],
                note: [null],
                resultsByMail: [false],
                invoiceMode: [InvoiceMode.IMMEDIATELY],
                samplingKitType: samplingKitTypeFC,
                samplingKitCount: samplingKitCount,
                customerId: [null],
                internalOrder: false,
                resultNotificationMode: [ResultNotificationMode.SCHEDULED],
                receiveEmails: [true],
                vouchersUsed: [null],
                resultsByEmail: [{ value: true, disabled: true }],
                invoiceNo: [''],
                orderDate: [new Date().toISOString(), Validators.required],
                paidOn: [null],
            }),
            samples: this.samplesFormArray,
        })

        samplingKitTypeFC.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
            samplingKitCount.updateValueAndValidity({ onlySelf: true, emitEvent: false })
        })
    }

    get f() {
        return this.mainForm.controls
    }

    ngOnInit() {
        this.customerId = this.auth.getCurrentUser().customerId
        this.customerSamples$ = this.samplesApi
            .getCustomerSamples({ customerId: this.customerId })
            .pipe(map((samples) => samples.map((s) => ({ id: s.id, label: this.getSampleLabel(s) }))))

        this.loading = true
        this.orderDraftApi
            .getActiveDrafts()
            .pipe(
                finalize(() => {
                    this.loading = false
                })
            )
            .subscribe({
                next: (drafts) => {
                    if (drafts.length > 0) {
                        const draft = drafts[0]
                        this.mainForm.patchValue(draft.content)
                        this.msgService.addSuccessMessage('order.draftLoaded')
                    }
                },
            })
        this.formPersistSub$ = this.mainForm
            .persist('orderDraft', {
                manager: new OrderPersistManager(),
                arrControlFactory: {
                    samples: (sample) => this.orderFB.createSampleForm(false, sample),
                },
                persistDisabledControls: true,
            })
            .pipe(
                untilDestroyed(this),
                debounceTime(2000),
                tap(() => (this.orderSaveInProgress = true)),

                switchMap((orderDraft) => {
                    return this.orderDraftApi.storeDraft({ body: orderDraft }).pipe(catchError((err) => of(null)))
                }),
                delay(1000),
                tap(() => (this.orderSaveInProgress = false)),
                finalize(() => (this.orderSaveInProgress = false))
            )
            .subscribe({
                next: (res) => {
                    console.log(`Order draft saved ${res}`)
                },
                error: (err) => {
                    console.log(`Error saving order draft`, err)
                },
            })
    }

    ngOnDestroy() {
        if (this.formPersistSub$) {
            this.formPersistSub$.unsubscribe()
        }
    }

    getSampleLabel(s: SampleListItem): string {
        return `${s.animalName || s.identifiers} | ${s.breed} |  ${new Date(s.samplingDate).toLocaleDateString(
            'sk',
            dateOptions
        )} ${s.labCode || 'N/A'}`
    }

    onAddSample() {
        let newSample: SampleFormModel

        if (this.samplesFormArray.controls.length > 0) {
            const lastSample = this.samplesFormArray.controls[
                this.samplesFormArray.controls.length - 1
            ] as FormGroup<SampleFormModel>
            newSample = {
                mode: 'NEW',
                animal: {
                    categoryId: lastSample.getRawValue().animal.categoryId,
                    rootCategoryId: lastSample.getRawValue().animal.rootCategoryId,
                },
                samplingByVet: lastSample.getRawValue().samplingByVet,
                vetAddress: lastSample.getRawValue().vetAddress,
                vetName: lastSample.getRawValue().vetName,
                vetNo: lastSample.getRawValue().vetNo,
                receivedDate: null,

                samplingDate: lastSample.getRawValue().samplingDate,
                extractionMethodId: lastSample.getRawValue().extractionMethodId,
            }
        }
        const newSampleForm = this.orderFB.createSampleForm(false)
        newSampleForm.patchValue(newSample, { emitEvent: false })
        this.samplesFormArray.push(newSampleForm)
    }

    sampleTrackFn(index, item) {
        return item.id || item.tempUUID
    }

    scrollTop() {
        window.scrollTo(0, 0)

        // const scrollElem = document.querySelector('.form-wizard-header')
        // scrollElem.scrollIntoView()
    }

    toCompletion() {
        this.step = 'COMPLETION'
        this.progress = 2
        this.scrollTop()
    }

    toSamples() {
        this.step = 'SAMPLES'
        this.progress = 1
        this.scrollTop()
    }

    confirmSingleSample() {
        if (this.samplesFormArray.length > 1) {
            this.toCompletion()
            return
        }
        this.confirmationService.confirm({
            icon: 'none',
            accept: () => {
                this.toCompletion()
            },
            key: 'confirmSimpleSample',
        })
    }

    confirmCancel() {
        this.confirmationService.confirm({
            icon: 'none',
            accept: () => {
                this.onCancel()
            },
            key: 'confirmCancel',
        })
    }

    toSummary() {
        this.step = 'SUMMARY'
        this.progress = 3
        this.scrollTop()
    }

    onCancel() {
        this.formPersistSub$.unsubscribe()
        localStorage.removeItem('orderDraft')

        this.orderDraftApi.deleteActiveDraft().subscribe({
            next: () => {
                this.router.navigate(['/'])
                this.msgService.addMessage({
                    severity: 'info',
                    message: 'order.cancellationOk',
                })
            },
            error: (err) => {
                this.msgService.addMessage({
                    severity: 'error',
                    message: 'common.saveNok',
                })
            },
        })
    }

    submit() {
        this.submitting = true
        const formData = this.mainForm.getRawValue() as OrderFormModel
        const toSubmit: SubmitNewOrderRequestParams = {
            onBehalfOfCustomer: false,
            newAdminOrderRequest: {
                ...formData.orderHeader,
                orderDate: new Date().toISOString(),
                paidOn: undefined,
                vouchersUsed: formData.orderHeader.vouchersUsed,
                samplingKitRequested: formData.orderHeader.samplingKitType != -1,
                samplingKitType:
                    formData.orderHeader.samplingKitType && formData.orderHeader.samplingKitType != -1
                        ? '' + formData.orderHeader.samplingKitType
                        : undefined,
                invoiceMode: InvoiceMode.IMMEDIATELY,

                samples: this.formSamplesToApiModel(formData.samples),
            },
        }
        this.orderApi
            .submitNewOrder(toSubmit)
            .pipe(
                finalize(() => {
                    this.submitting = false
                })
            )
            .subscribe(
                (res) => {
                    this.msgService.bigSuccessMessage('order.newSaveOk')
                    localStorage.removeItem('orderDraft')
                    this.router.navigate(['/new-order-summary', res])
                },
                (err) => {
                    this.msgService.addMessage({
                        severity: 'error',
                        message: 'order.saveError',
                        detail: 'common.errorHandled',
                    })
                }
            )
    }

    formSamplesToApiModel(samples: SampleFormModel[]): NewSampleDTO[] {
        return samples.map((s) => ({
            ...s,
            // ownerName: (s.ownerName || s.ownerSurname)? '' : undefined,
            animal: !s.existingSampleId
                ? {
                      ...s.animal,
                      ...s.animal.identifiers,
                      dateOfBirth: s.animal.dateOfBirth,
                  }
                : undefined,
            receivedDate: s.receivedDate,
            samplingDate: s.samplingDate,
            items: this.formSamplesToApiItemsModel(s.items),
        }))
    }
    formSamplesToApiItemsModel(formItems: ItemFormModel[]): NewOrderItemDTO[] {
        const res: NewOrderItemDTO[] = []
        formItems.forEach((i) => {
            const item: NewOrderItemDTO = {
                ...i,
                analysisDate: i.analysisDate,
                resultId: i.resultId,
            }
            res.push(item)
        })
        return res
    }
}
