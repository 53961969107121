import { registerLocaleData } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import localeEn from '@angular/common/locales/en'
import localeSk from '@angular/common/locales/sk'
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ApiModule, BASE_PATH } from '@slovgen-ui/api'
import { OrderComponentsModule } from '@slovgen-ui/order-components'
import { Environment, MissingI18nService, SharedModule, appInitializer } from '@slovgen-ui/shared'
import { NgxPrintModule } from 'ngx-print'
import { ToastrModule } from 'ngx-toastr'
import { ButtonModule } from 'primeng/button'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { TableModule } from 'primeng/table'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { MyOrdersComponent } from './pages/my-orders/my-orders.component'
import { MySamplesComponent } from './pages/my-samples/my-samples.component'
import { NewOrderComponent } from './pages/new-order/new-order.component'
import { OrderCompletionComponent } from './pages/new-order/order-completion.component'
import { OrderReviewComponent } from './pages/new-order/order-review/order-review.component'
import { OrderSummaryComponent } from './pages/new-order/order-summary/order-summary.component'
import { OrderDetailComponent } from './pages/order-detail/order-detail.component'

import * as Sentry from '@sentry/angular-ivy'
import { BlockUIModule } from 'primeng/blockui'
import { CalendarModule } from 'primeng/calendar'
import { CheckboxModule } from 'primeng/checkbox'
import { TooltipModule } from 'primeng/tooltip'
import { environment } from '../environments/environment'

import { DropdownModule } from 'primeng/dropdown'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputTextModule } from 'primeng/inputtext'
import { ActivationComponent } from './activation/activation.component'

registerLocaleData(localeSk)
registerLocaleData(localeEn)

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, `${location.origin}/assets/i18n/`, '.json?v=' + environment.version)
}

const errHandler = environment.production
    ? [
          {
              provide: ErrorHandler,
              useValue: Sentry.createErrorHandler({
                  showDialog: true,
                  logErrors: true,
              }),
          },
      ]
    : []

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        MyOrdersComponent,
        NewOrderComponent,
        MySamplesComponent,
        OrderDetailComponent,
        OrderCompletionComponent,
        OrderSummaryComponent,
        OrderReviewComponent,
        ActivationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ApiModule,
        AppRoutingModule,
        CalendarModule,
        NgxPrintModule,
        OrderComponentsModule,
        InputTextModule,
        SharedModule,
        TableModule,
        BlockUIModule,
        ButtonModule,
        InputNumberModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ConfirmDialogModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MissingI18nService,
            },
        }),
        ToastrModule.forRoot(),
    ],
    providers: [
        {
            provide: BASE_PATH,
            useValue: './api',
        },
        { provide: Environment, useValue: environment },
        ...errHandler,
        appInitializer,
        {
            provide: LOCALE_ID,
            deps: [TranslateService], //some service handling global settings
            useFactory: (translateService: TranslateService) => `${translateService.currentLang}-SK`, //returns locale string
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
